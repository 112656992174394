import * as React from "react";
import Add from "@mui/icons-material/Add";
import AppBar from "@mui/material/AppBar";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { DialogContent, CircularProgress, Fab, Alert } from "@mui/material";
import { useNavigate, useParams } from "react-router";

import { AssignedCleanerCard } from "../../containers/locations/cards";
import { GeneralReducerActionType } from "../../reducers";
import { Schedule, ScheduleFrequency } from "../../types";
import { getLocation, getLocationSchedules } from "../../utils";
import { useEmployees } from "hooks/useEmployees";
import { useLocations } from "hooks/useLocations";

import styles from "./AssignedCleanersForm.module.scss";
import { useSchedules } from "hooks/useSchedules";
import { Status } from "types/status";
import { frequentShiftsService } from "services";

const { LOADING } = GeneralReducerActionType;

function AssignedCleanersForm() {
  const [scheduleChanges, setScheduleChanges] = React.useState<
    Omit<Schedule, "timestamp">[]
  >([]);
  const employees = useEmployees();
  const schedules = useSchedules();
  const locations = useLocations();
  const { id: locationId } = useParams();
  const navigate = useNavigate();
  const location = getLocation(locations.data, locationId!!);
  const [submissionStatus, setSubmissionStatus] = React.useState(Status.Idle);

  /** Update state schedules */
  React.useEffect(() => {
    const locationSchedules =
      getLocationSchedules(schedules.data, locationId!!) || [];
    setScheduleChanges(locationSchedules);
  }, [locationId, schedules.data]);

  /** Handles deletion */
  function handleDelete(i: number) {
    setScheduleChanges(
      Object.assign([], scheduleChanges, {
        [i]: {
          ...(scheduleChanges![i] as Schedule),
          deleted: true,
        },
      })
    );
  }

  /** Adds a new cleaner to the list */
  function handleAddNewCleaner() {
    const tempId = scheduleChanges.length;
    const schedulesCopy = [...scheduleChanges];
    schedulesCopy.unshift({
      newShift: true,
      id: tempId.toString(),
      location: locationId!!,
      weekdays: [],
      deleted: false,
      duration: "",
      employee: "",
      start: "",
      end: "",
      frequency: ScheduleFrequency.Weekly,
    });
    setScheduleChanges(schedulesCopy);
  }

  /** Handles closing */
  function handleClose() {
    navigate(`/locations/${locationId}`);
  }

  function validateSchedules() {
    if (scheduleChanges.length) {
      const errorFields = scheduleChanges.reduce<string[]>(
        (missingFields, schedule) => {
          if (!schedule.deleted) {
            let fields = [];
            if (!schedule.end) {
              fields.push("end time");
            }
            if (!schedule.start) {
              fields.push("start time");
            }
            if (!schedule.duration) {
              fields.push("duration");
            }
            if (!schedule.frequency) {
              fields.push("frequency");
            }
            return [...missingFields, ...fields];
          }
          return missingFields;
        },
        []
      );
      if (errorFields.length) {
        return false;
      }
    }
    return true;
  }

  /** Handles save */
  async function handleSave() {
    const valid = validateSchedules();
    if (valid) {
      setSubmissionStatus(Status.Pending);
      await frequentShiftsService.update(scheduleChanges);
      setSubmissionStatus(Status.Success);
    } else {
      setSubmissionStatus(Status.Error);
    }
  }

  function handleChange(
    index: number,
    property: string,
    value: string | string[]
  ) {
    setScheduleChanges(
      Object.assign([], scheduleChanges, {
        [index]: {
          ...(scheduleChanges![index] as Schedule),
          [property]: value,
        },
      })
    );
  }

  return (
    <Dialog fullScreen={true} open={true} onClose={handleClose}>
      <AppBar className={styles.appBar}>
        <Toolbar>
          <IconButton color="inherit" onClick={handleClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={styles.flex}>
            Assigned Cleaners | {location && location.name}
          </Typography>
          <IconButton
            color="inherit"
            onClick={handleSave}
            disabled={submissionStatus === Status.Pending}
          >
            {submissionStatus === Status.Pending ? (
              <CircularProgress color="secondary" size={16} />
            ) : (
              <SaveIcon />
            )}
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent className={styles.content}>
        {submissionStatus === Status.Error ? (
          <Alert variant="filled" severity="error" className={styles.alert}>
            One or more fields are missing selections.
          </Alert>
        ) : null}
        {submissionStatus === Status.Success ? (
          <Alert variant="filled" severity="success" className={styles.alert}>
            Assigned cleaners updated successfully.
          </Alert>
        ) : null}
        {[schedules.status, employees.status].includes(LOADING) ? (
          <CircularProgress />
        ) : scheduleChanges.length ? (
          scheduleChanges.map((schedule, index) => (
            <AssignedCleanerCard
              employees={employees.data}
              key={schedule.id}
              onChange={(property: string, value: string | string[]) =>
                handleChange(index, property, value)
              }
              onDelete={() => handleDelete(index)}
              schedule={schedule}
            />
          ))
        ) : (
          "No assigned cleaners"
        )}
      </DialogContent>
      <Fab
        onClick={handleAddNewCleaner}
        className={styles.fab}
        color="primary"
        variant="extended"
      >
        <Add />
        Add New Cleaner
      </Fab>
    </Dialog>
  );
}

export default AssignedCleanersForm;
